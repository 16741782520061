import React, { useEffect,useState } from 'react'
import { Link } from 'react-router-dom';
import Creator from '../../components/creator';
import Collections from '../../components/collections';
import DiscoverItems from '../../components/discover-items';
import QA from '../../components/qa';
import GetTouch from '../../components/get-touch';
import Footer from '../../components/footer';
import Navbar from '../../components/navbar';
import Blog from '../../components/blog';
import BlogDetail from '../blog/blog-detail';
import About from '../about';
import Wallet from '../wallet';
import EnrollButton from '../enrollbutton-home';
import Contact from '../contact';
import HelpcenterFaqs from '../helpcenter/helpcenter-faqs';
import Switcher from '../../components/switcher';

export default function Index() {

    useEffect(() => {
        document.documentElement.classList.add('dark');
        document.body.classList.add('font-urbanist', 'text-base', 'text-black', 'dark:text-white', 'dark:bg-slate-900');
    }, []);

    const [showEnrollButton, setShowEnrollButton] = useState(false);
    const [hideEnrollButtonInFooter, setHideEnrollButtonInFooter] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
          if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            setShowEnrollButton(true);
          } else {
            setShowEnrollButton(false);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    
      const handleEnrollClick = () => {
        // Redirect to the Square checkout link
        window.location.href = 'https://checkout.square.site/merchant/MLR47B9DMQFVB/checkout/ZZS6PYU3KSQXRYQNQN6MEYAD';
    };
    

    useEffect(() => {
        // Check if the user has scrolled past a certain point to hide the button in the footer
        const handleFooterScroll = () => {
            const footer = document.getElementById('footer'); // Change 'footer' to the actual ID of your footer element
            const scrollPosition = window.innerHeight + window.scrollY;

            if (footer && scrollPosition > footer.offsetTop) {
                setHideEnrollButtonInFooter(true);
            } else {
                setHideEnrollButtonInFooter(false);
            }
        };

        window.addEventListener('scroll', handleFooterScroll);

        return () => {
            window.removeEventListener('scroll', handleFooterScroll);
        };
    }, []);

    const spanStyle = {
        background: `linear-gradient(90deg, #4bcaf7,#1ca96d,#d074ad, #f36a7a, #ffe581)`, 
        WebkitBackgroundClip: 'text',
        color: 'transparent',
        display: 'inline-block',
      };
      
      

    return (
        <>
            <span className="fixed blur-[200px] w-[600px] h-[600px] rounded-full top-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2 -translate-y-1/2 bg-gradient-to-tl from-red-600/20 to-violet-600/20 dark:from-red-600/40 dark:to-violet-600/40"></span>
            <Navbar />
            <section className="relative md:pt-48 pt-36 overflow-hidden" id='home'>
                <div className="container">
                    <div className="grid grid-cols-1 justify-center text-center" style={{marginTop:'-60px'}}>
                        <div className="relative">
                            <div className="relative mb-5">
                                <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl">Revolutionize Your Business Today <br /> Grow, Scale &  <span className="bg-gradient-to-l from-red-600 to-violet-600 text-transparent bg-clip-text">Automate with AI</span></h1>

                                {/* <div className="overflow-hidden after:content-[''] after:absolute after:h-10 after:w-10 after:bg-violet-600/10 dark:after:bg-violet-600/30 after:-top-[50px] after:start-[30%] after:-z-1 after:rounded-lg after:animate-[spin_10s_linear_infinite]"></div> */}

                                {/* <div className="overflow-hidden after:content-[''] after:absolute after:h-10 after:w-10 after:bg-violet-600/20 dark:after:bg-violet-600/40 after:bottom-[0] after:end-[15%] after:-z-1 after:rounded-full after:animate-ping"></div> */}
                            </div>
                            <p className="text-slate-400 dark:text-white/70 text-lg max-w-xl mx-auto">Effortless Scaling and Automation with AI: Skip the Technical Complexity and Time Drain</p>
                            
                            <div style={{marginTop:'40px'}}>
                                <h2 className='font-bold lg:leading-snug leading-snug text-4xl lg:text-4xl blinking-text'>🌸<span className="font-bold lg:leading-snug leading-snug text-4xl lg:text-4xl " style={spanStyle}>SPRING SALE - Now Only <span> $39.99  </span> </span> <del style={{ textDecoration: 'line-through', color: 'red' }}> $299</del>🌼</h2>
                            </div>
                            
                            <div className="mt-6">
                                    <Link to="https://checkout.square.site/merchant/MLR47B9DMQFVB/checkout/ZZS6PYU3KSQXRYQNQN6MEYAD" className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full me-2 mt-2 inline-flex items-center">Enroll Now to Secure Your Future</Link>
                                </div>
                                

                        </div>
                        
                    </div>

                    {/* <div className="relative animate-[spin_30s_linear_infinite] -z-1">
                        <span className="after:absolute after:start-0 after:bottom-1/2 after:translate-y-1/2 after:h-2 after:w-8 after:rounded-md after:bg-violet-600/20 relative after:z-10"></span>
                        <span className="after:absolute after:start-0 after:bottom-1/2 after:translate-y-1/2 after:rotate-90 after:h-2 after:w-8 after:rounded-md after:bg-violet-600/20 relative after:z-10"></span>
                    </div> */}
                </div>
                {/* <Collections/> */}
            </section>
            <section>
            {hideEnrollButtonInFooter ? null : (
    <div>
        <button
            id="enrollButton"
            className={`btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full me-2 mt-2 inline-flex items-center ${showEnrollButton ? 'show' : ''}`}
            onClick={handleEnrollClick}
        >
            Enroll Now to Secure Your Future
        </button>
    </div>
)}

            
            <section className="relative md:py-14 py-16" id= 'blog' style={{marginTop:"0px"}}>
                
                <section >
                <Blog/>
                {/* <DiscoverItems title='Discover Items'/> */}
                {/* <Creator title="Best Creators & Sellers" description="We are a huge marketplace dedicated to connecting great artists of all Giglink with their fans and unique token collectors!" /> */}
                {/* <QA /> */}
            
                </section>
                <GetTouch />
            </section>
            <section id='payments'><Wallet/></section>
            <section id='aboutus'><About/></section>
            <section id='FAQ'><HelpcenterFaqs/></section>
            <section id='contact'><Contact/></section>         
            </section>
            <section id='footer'><Footer /></section>         

            
            {/* <Switcher /> */}
        </>
    )
}
