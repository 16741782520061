import React, { useEffect } from 'react'
import metamask from '../assets/images/wallet/metamask.png';
import alpha from '../assets/images/wallet/alpha.png';
import binance from '../assets/images/wallet/binance.png';
import bitski from '../assets/images/wallet/bitski.png';
import coinbase from '../assets/images/wallet/coinbase.png';
import enjin from '../assets/images/wallet/enjin.png';
import formatic from '../assets/images/wallet/formatic.png';
import pionex from '../assets/images/wallet/pionex.png';
import torus from '../assets/images/wallet/torus.png';
import trustwallet from '../assets/images/wallet/trustwallet.png';
import connect from '../assets/images/wallet/wallet-connect.png';
import Navbar from '../components/navbar'
import Footer from '../components/footer';
import Switcher from '../components/switcher';
import { Link } from 'react-router-dom';
import ai from '../assets/images/spring_sale.png';
import {MdOutlineArrowForward} from "../assets/icons/vander"
// import ai from '../assets/images/ai-mastermind.png';
import "../../node_modules/react-modal-video/scss/modal-video.scss";

export default function Wallet() {
    useEffect(() => {
        document.documentElement.classList.add('dark');
    }, []);
    const master = 'https://toppng.com/uploads/preview/mastercard-logo-png-image-background-logo-mastercard-11563000929p7h8ctftya.png';
    const visa = 'https://toppng.com/uploads/preview/visa-logo-no-background-11661940005ndl1tdpuck.png';
    const apple = 'https://toppng.com/uploads/preview/apple-pay-logo-11563206321dtmtsc1k8f.png';
    const google = 'https://starpng.com/public/uploads/preview/google-pay-logo-icon-png-transparent-background-image-for-free-download-11582215961bnhidc3lm7.png';
    // const ai = 'https://hostedimages-cdn.aweber-static.com/MTgzODI3OQ==/optimized/4cfc1a535c8e4ee791c3536dc2f231b7.png';
    const money ='https://hostedimages-cdn.aweber-static.com/MTgzODI3OQ==/original/adf3e19411d24ecca9dabe46e49e32c5.png';
    const walletData = [
        {
            title: 'Mastercard',
            image: master
        },
        {
            title: 'Visa',
            image: visa
        },
        {
            title: 'Apple Pay',
            image: apple
        },
        {
            title: 'Google Pay',
            image: google
        },
        

    ]
    return (
        <>
            {/* <Navbar /> */}
            <section className="relative table w-full py-36 bg-[url('../../assets/images/bg/bg1.jpg')] bg-bottom bg-no-repeat">
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        {/* <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Payments</h3> */}
                        <h3 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-5xl "><span className='bg-gradient-to-l from-red-600 to-violet-600 text-transparent bg-clip-text'>Pay</span>ments</h3>

                    </div>
                </div>

                {/* <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Giglink</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">About Us</li>
                    </ul>
                </div> */}
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-5">
                            <div className="relative">
                                <div className="absolute inset-0 bg-gradient-to-r from-red-600 to-violet-600 rounded-lg -mt-[15px] -ms-[15px] h-[98%] w-[98%] -z-1"></div>
                                <img src={ai} className="rounded-lg shadow-md dark:shadow-gray-800" alt="" />
                                
                            </div>
                        </div>

                        <div className="lg:col-span-7">
                            <div className="lg:ms-5">
                                <h3 className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold" style={{color:'#38c54d'}}>Now Available For <br /> Instant Digital Access!!</h3><br></br>
                                <h5 className="mb-2 md:text-2xl text-2xl md:leading-snug leading-snug font-semibold">⏳ SPRING SALE!</h5><br></br>

                                <p className="text-red-600 font-medium">ONLY $39.99 TODAY</p><br></br>
                                <p className="text-violet-600 font-medium">YOU'RE SAVING: $260</p><br></br>
                                <h5 className="mb-2 md:text-2xl text-2xl md:leading-snug leading-snug font-semibold">Get Access Now With 10 Bonuses</h5>
                                <p className="text-slate-400 max-w-xl mt-4" >⬇️ <span style={{color:'#38c54d'}}>Now Available For Instant Access</span></p>
                                <p className="text-slate-400 max-w-xl mt-4">Delivered instantly. Start learning now.</p>

                                <div className="mt-6">
                                    <Link to="https://checkout.square.site/merchant/MLR47B9DMQFVB/checkout/ZZS6PYU3KSQXRYQNQN6MEYAD" className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full me-2 mt-2 inline-flex items-center">Enroll Now to Secure Your Future</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

    

            {/* <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid xl:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 grid-cols-1 gap-[30px]">
                                <div className="group relative rounded-xl bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 p-6 text-center mt-10 hover:shadow-md dark:hover:shadow-gray-800 transition-all duration-500">
                                    <div className="relative -mt-16">
                                        <img src={money} className="bg-white dark:bg-slate-900 h-20.5 w-20.5 rounded-full shadow-md dark:shadow-gray-800 mx-auto p-3" alt="" />
                                        <h5 className="text-lg font-semibold mt-5">Experience Our Commitment: 1-Year Unconditional Money-Back Guarantee</h5>
                                    </div>
                                </div>    
                    </div>
                </div>
            </section> */}

            <section className="relative md:py-24 py-16">
                <div className="container" style={{marginTop:"-80px"}}>
                    <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-[30px]">
                        {
                            walletData.map((ele, index) => (
                                <div key={index} className="group relative rounded-xl bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 p-6 text-center mt-10 hover:shadow-md dark:hover:shadow-gray-800 transition-all duration-500">
                                    <div className="relative -mt-16">
                                        <img src={ele.image} className="bg-white dark:bg-slate-900 h-20 w-20 rounded-full shadow-md dark:shadow-gray-800 mx-auto p-3" alt="" />
                                        <h5 className="text-lg font-semibold mt-5">{ele.title}</h5>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
            {/* <Footer /> */}
            {/* <Switcher /> */}
        </>
    )
}
