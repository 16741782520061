import item1 from '../assets/images/items/1.jpg';
import item2 from '../assets/images/items/2.gif';
import item3 from '../assets/images/items/2.jpg';
import item4 from '../assets/images/items/3.jpg';
import item5 from '../assets/images/items/3.gif';
import item6 from '../assets/images/items/4.jpg';
import item7 from '../assets/images/items/5.jpg';
import item8 from '../assets/images/items/6.jpg';
import item9 from '../assets/images/items/8.jpg';
import item10 from '../assets/images/items/4.gif';
import item11 from '../assets/images/items/5.gif';
import item12 from '../assets/images/items/9.jpg';
import item13 from '../assets/images/items/1.gif';
import item14 from '../assets/images/items/20.jpg';
import item15 from '../assets/images/items/7.jpg';
import item16 from '../assets/images/items/10.jpg';
import item17 from '../assets/images/items/11.jpg';
import item18 from '../assets/images/items/12.jpg';
import item19 from '../assets/images/items/16.jpg';
import item22 from '../assets/images/items/14.jpg';
import item23 from '../assets/images/items/7.gif';
import item24 from '../assets/images/items/8.gif';
import item25 from '../assets/images/items/15.jpg';
import item26 from '../assets/images/items/17.jpg';
import item27 from '../assets/images/items/18.jpg';
import item28 from '../assets/images/items/19.jpg';

import image1 from '../assets/images/avatar/1.jpg';
import image2 from '../assets/images/avatar/2.jpg';
import image3 from '../assets/images/items/3.gif';
import image4 from '../assets/images/avatar/3.jpg';
import image5 from '../assets/images/avatar/4.jpg';
import image6 from '../assets/images/avatar/5.jpg';
import image7 from '../assets/images/avatar/6.jpg';
import image8 from '../assets/images/items/5.gif';
import image9 from '../assets/images/avatar/7.jpg';
import image10 from '../assets/images/items/5.gif';
import image11 from '../assets/images/avatar/8.jpg';
import image12 from '../assets/images/items/2.gif';

import blog1 from '../assets/images/blog/AI-blog-1.png';
import blog2 from '../assets/images/blog/AI-blog-2.png';
import blog3 from '../assets/images/blog/AI-blog-3.png';
import blog4 from '../assets/images/blog/AI-blog-4.png';
import blog5 from '../assets/images/blog/AI-blog-5.png';
import blog6 from '../assets/images/blog/AI-blog-6.png';
import blog7 from '../assets/images/blog/AI-blog-7.jpg';
import blog8 from '../assets/images/blog/AI-blog-8.jpg';
import blog9 from '../assets/images/blog/AI-blog-9.png';
import blog10 from '../assets/images/blog/10.jpg';
import blog11 from '../assets/images/blog/11.jpg';
import blog12 from '../assets/images/blog/12.jpg';

export const data = [
     {
        id:1,
        image: item1,
        subtext: '@StreetBoy',
        title: 'Genuine Undead #3902',
        avatar: image1,
        name:"Steven Townsend",
        date: 'December 29, 2023 6:0:0',
        liveDate:'September 13, 2024 6:0:0',
        category: 'Games',
        subImage:[
            {
                index:1,
                image:item13,
            },
            {
                index:2,
                image:item3,
            },
            {
                index:3,
                image:item4,
            },
        ]
        },
        {
            id:2,
            image: item2,
            subtext: '@CutieGirl',
            title: 'Windchime #768',
            avatar: image2,
            name:"Tiffany Betancourt",
            liveDate:'November 29, 2024 6:0:0',
             category: 'Games',
            subImage:[
                {
                    index:4,
                    image:item1,
                },
                {
                    index:5,
                    image:image12,
                },
                {
                    index:6,
                    image:item6,
                },
            ]
        },
        {
            id:3,
            image: item3,
            subtext: '@ButterFly',
            title: 'Probably A Label #3277',
            avatar: image3,
            name:"Jacqueline Burns",
            liveDate:'March 13, 2024 6:0:0',
            category: 'Art',
            subImage:[
                {
                    index:7,
                    image:item14,
                },
                {
                    index:8,
                    image:item13,
                },
                {
                    index:9,
                    image:item5,
                },
            ]
        }, {
            id:4,
            image: item4,
            subtext: '@NorseQueen',
            title: 'Probably A Label #1711',
            avatar: image4,
            name:"Mari Harrington",
            date: 'January 13, 2024 5:3:1',
            liveDate:'May 6, 2024 6:0:0',
             category: 'Music',
            subImage:[
                {
                    index:10,
                    image:item7,
                },
                {
                    index:11,
                    image:item8,
                },
                {
                    index:12,
                    image:item5,
                },
            ]
        }, {
            id:5,
            image: item5,
            subtext: '@BigBull',
            title: 'Shibuya Scramble Punks',
            avatar: image5,
            name:"Floyd Glasgow",
            liveDate:'July 19, 2024 6:0:0',
            category: 'Video',
            subImage:[
                {
                    index:13,
                    image:item15,
                },
                {
                    index:14,
                    image:item9,
                },
                {
                    index:15,
                    image:item12,
                },
            ]
        }, {
            id:6,
            image: item6,
            subtext: '@Angel',
            title: 'Probably A Label #650',
            avatar: image6,
            name:"Donna Schultz",
            liveDate:'July 20, 2024 6:0:0',
            category: 'Games',
            subImage:[
                {
                    index:16,
                    image:item10,
                },
                {
                    index:17,
                    image:item3,
                },
                {
                    index:18,
                    image:item11,
                },
            ]
        }, {
            id:7,
            image: item7,
            subtext: '@CrazyAnyone',
            title: 'Looki#0147',
            avatar: image7,
            name:"Joshua Morris",
            date: 'December 29, 2023 6:0:0',
            liveDate:'February 17, 2024 6:0:0',
            category: 'Games',
            subImage:[
                {
                    index:19,
                    image:item16,
                },
                {
                    index:20,
                    image:item17,
                },
                {
                    index:21,
                    image:item18,
                },
            ]
        }, {
            id:8,
            image: item8,
            subtext: '@Princess',
            title: 'Poob #285',
            avatar: image8,
            name:"Rosaria Vargas",
            liveDate:'April 15, 2024 6:0:0',
            category: 'Art',
            subImage:[
                {
                    index:22,
                    image:item7,
                },
                {
                    index:24,
                    image:item9,
                },
                {
                    index:24,
                    image:item19,
                },
            ]
        },
        {
            id:9,
            image: item9,
            subtext: '@LooserBad',
            title: 'Umber Arrow',
            avatar: image9,
            name:"Carl Williams",
            liveDate:'May 17, 2024 6:0:0',
            category: 'Music',
            subImage:[
                {
                    index:25,
                    image:item22,
                },
                {
                    index:26,
                    image:item23,
                },
                {
                    index:27,
                    image:item24,
                },
            ]
        },
        {
            id:10,
            image: item10,
            subtext: '@Princess',
            title: 'Gloam Druid',
            avatar: image10,
            name:"Rosaria Vargas",
            liveDate:'August 8, 2024 6:0:0',
             category: 'Memes',
            subImage:[
                {
                    index:28,
                    image:item25,
                },
                {
                    index:29,
                    image:item19,
                },
                {
                    index:30,
                    image:item26,
                },
            ]
        },
        {
            id:11,
            image: item11,
            subtext: '@PandaOne',
            title: 'Azuki #7421',
            avatar: image11,
            name:"Julius Canale",
            liveDate:'December 27, 2024 6:0:0',
             category: 'Memes',
            subImage:[
                {
                    index:31,
                    image:item27,
                },
                {
                    index:32,
                    image:item24,
                },
                {
                    index:33,
                    image:item28,
                },
            ]
        },
        {
            id:12,
            image: item12,
            subtext: '@FunnyGuy',
            title: 'Wolf-Cult Vanguard',
            avatar: image12,
            name:"Michael Williams",
            liveDate:'December 25, 2024 6:0:0',
            category: 'Music',
            subImage:[
                {
                    index:34,
                    image:item7,
                },
                {
                    index:35,
                    image:item14,
                },
                {
                    index:36,
                    image:item19,
                },
            ]
        },
]
export const images = [item13,item3,item4,item1,image12,item6, item14, item13,item5, item7, item8, item5, item15, item9, item12, item10, item3, item11, item16 ,item17, item18, item7, item9, item19, item22, item23, item24, item25, item19, item26, item27, item24, item28, item7, item14, item19  ]

export  const blogData = [
    {
        id:1,
        title: 'AI-Mastermind-Course',
        image: blog1,
        category: 'Courses',
        subtext: '@StreetBoy',
        date:"13th September,2023",
        video:'AI Mastermind Course Introduction Video Below 👇',
        title2:"What This Means for You and Your Business:",
        title3:"Your Gains with Our Course:",
        content1:"🌟 Eye-Opening Insight...",
        content2:"⏰ Save Over 10,000 Hours Annually!",
        content3:"💡 This is the staggering time-saving potential for businesses fully leveraging AI & ChatGPT through our course!",
        content4:"🌟 Transform Your Business with AI Mastermind Course",
        content5:"🚀 Business Growth Revolutionized",
        content6:"Every successful enterprise is built on two key aspects : ",
        content7:"Revenue Generation: 💰 Amplify your earnings with cutting-edge AI strategies.",
        content8:"⏳ Time: Your Invaluable Resource",
        content9:"Operational Efficiency: 🛠️ Achieve a streamlined, lean operation. Minimize staff requirements and step back from the daily grind to focus on strategic growth.",
        content15:"Recognizing time as your most precious asset, we've developed the AI Mastermind Course. This is more than just education; it's a course for success!",
        content16:"💸 Boost Income: Leverage AI for smarter, more profitable decisions.",
        content17:"🤖 Automate Routine Tasks: Say goodbye to tedious, manual processes.",
        content18:"📈 Scale Your Business: Grow your enterprise without the hassle of managing a large workforce.",
        content19:"🏆 Excel in Competitive Markets",
        content20:"Stand out and dominate with the AI Mastermind Course. Use AI to excel in your industry, outperform competitors, and lead in innovation.",
        content21:"🌈 The Potential is Limitless!",

    },
    {
        id:2,
        title: 'AI-Mastermind-Course-Offers',
        image: blog2,
        category: 'Offers',
        subtext: '@CutieGirl',
        date:"29th November, 2023",
        video:'🚀 The Offer AI Mastermind Course Video Below 👇',
        title2:"🌟 Here's Everything Included",
        content1:"🌐 200+ GPT Modules: Explore a wide array of modules on AI and ChatGPT applications in business.",
        content2:"✨ Unlimited Prompt Creation: Unleash endless possibilities with custom ChatGPT prompts for your specific business needs.",
        content3:"🎥 200+ Training Videos: Access an extensive collection of engaging videos for easy and effective AI learning.",
        content4:"💰 AI-Driven Revenue Strategies: Harness innovative AI techniques to significantly increase your income.",
        content5:"🚀 Streamlined Operational Efficiency: Implement strategies to make your business leaner and more cost-effective.",
        content6:"🤖 Automate and Save Time: Learn to automate routine tasks, freeing up valuable time for strategic initiatives.",
        content7:"⚡ Business Scaling Secrets: Discover efficient ways to scale your business without the complexities of managing a large team.",
        content8:"🏆 Competitive Market Advantage: Use AI to gain an edge over competitors and lead in your industry.",
    },
    {
        id:3,
        title: '10-Bonuses-For-Your-Business-with-AI-Mastermind-Course ',
        image: blog3,
        category: 'Business',
        subtext: '@ButterFly',
        date: "29th December, 2023",
        video:'🌟 What Is AI Mastermind Course Video Below 👇',
        title2:"10 Bonuses Included Today ($249 Value)",
        content1:"💸 Limited-Time Discount: A significant discount for initial enrollees.",
        content2:"🔑 Bonus Module Access: Early access to an advanced module on AI strategy.",
        content3:"⏳ Extended Coaching Sessions: Extra coaching time for the first 50 participants.",
        content5:"🎁 One-on-One Coaching Sessions: Personalized coaching with AI experts tailored to your business needs.",
        content6:"🎁 Exclusive Community Access: Membership to a private community for networking and shared learning.",
        content7:"🎁 AI Tool Beta Testing: Early access to innovative AI tools in their beta stages.",
        content8:"🎁 Specialized Mini-Courses: Additional courses on niche topics like AI in finance and data security.",
        content9:"🎁 Lifetime Updates: Continuous access to all future course updates and materials.",
        content10:"🎁 Expert Webinar Series: Access to exclusive webinars led by industry leaders and AI pioneers.",
        content11:"🎁 Customizable AI Templates: A range of ready-to-use AI templates for various business scenarios.",
        content12:"🎁 AI Integration Playbook: A comprehensive guide to integrating AI into different aspects of your business.",
        content13:"🎁 Advanced Analytics Tools: Exclusive tools for deep insights into business performance and customer behavior.",
        content14:"🎁 Priority Support: Fast-track assistance and support from our team of AI experts.",
    },
    {
        id:4,
        title: 'Transforming-Your-Business-with-AI-Mastermind-Course',
        image: blog4,
        category: 'Business',
        subtext: '@NorseQueen',
        date:'13th March, 2023', 
        title2:"📢 Real Voices, Real Impact:",
        content1:"💵 Revenue Generation Mastery: Amplify your earning capacity through innovative AI strategies.",
        content2:"🚀 Operational Streamlining: Achieve a sleek, efficient operation with minimal staff, freeing you from daily tasks to focus on expansive business growth.",
        content3:"⏳ Time: Your Most Precious Commodity",
        content4:"🌟 Recognizing the value of your time, we've crafted the AI Mastermind Course. It's not just learning; it's strategic empowerment!",
        content5:"🌟 Hear firsthand from those who've experienced the transformative power of the course.",
        content6:"🔓🌐 Unlock the Power of AI Across All Markets with the AI Mastermind Course!",
        content22:"This course transformed how i approach AI in my business. The practical insights and tools were game-changers",
        content23:"- James P",
        content231:"Entrepreneur",
        content24:"The ultimate AI mastery course demystified AI for me. It's comprehensive, yet easy to grasp, even for beginners",
        content25:"- Sara L",
        content251:"Marketing",
        content26:"I saved countless hours in my workflow thanks to the strategies i learned. Highly recommend it !",
        content27:"- David K",
        content271:"Business Analyst",
        
    },
    {
        id:5,
        title: 'Enroll-now-and-be-part-of-the-AI-revolution!',
        image: blog5,
        category: 'AI Markets',
        subtext: '@@BigBull',
        date:"6th June, 2023",
        title2:"But wait...There's more!",
        content1:"⚡ Over 200 custom GPTs all pre-trained and done-for-you to provide a fully automated process, saving you 20+ hours every week!",
        content2:"🌎 Whether you're sparking innovation in your current role or forging a new path, the AI Mastermind Course is the key to unlocking your full potential. Used successfully by professionals across every market, our course is tailored to empower",
        content3:"🤖 No matter your field, the AI Mastermind Course is your gateway to mastering the digital landscape. Join a diverse community of professionals harnessing the power of AI to excel and innovate. Enroll now and be part of the AI revolution!",
        content5:"✅ Course Creators: Craft AI-enhanced content that captivates and educates.",
        content6:"✅ Coaches & Consultants: Leverage AI to provide insights and strategies with precision.",
        content8:"✅ Agencies & Freelancers: Infuse AI into your creative solutions and services.",
        content9:"✅ Digital Product Sellers: Optimize your sales funnel with AI-driven data analytics.",
        content10:"✅ Speakers & Experts: Enhance your influence with AI-curated presentations.",
        content11:"✅ Authors & Bloggers: Generate captivating content with AI-assisted writing tools.",
        content12:"✅ Ecommerce Stores: Revolutionize customer experience with AI-powered personalization.",
        content13:"✅ Service Businesses: Streamline operations with AI automation.",
        content14:"✅ B2B Companies: Forge stronger partnerships and smarter business models with AI.",
        content15:"✅ Local Stores: Attract a loyal customer base with AI-targeted marketing.",
        content16:"✅ Professional Services: Upgrade your expertise with AI-optimized practices.",
        content17:"✅ Contractors: Plan and predict project outcomes with AI algorithms.",
        content18:"✅ Network Marketers: Expand your network with AI-driven engagement strategies.",
        content19:"✅ Digital Marketers: Stay ahead of trends with AI's deep learning insights.",
        content20:"✅ Copywriters: Craft compelling copy with AI language models.",
        content21:"✅ Affiliates: Boost your affiliate success with AI-recommended products.",
        content28:"✅ Email Marketers: Personalize campaigns at scale with AI analytics.",
        content29:"✅ And many more industries ready to be transformed by AI!",
    },
    {
        id:6,
        title: 'Master-AI-Strategies',
        image: blog6,
        category: 'Strategies',
        subtext: '@Angel',
        date:'19th July, 2023',
        title2:'🚀 Skyrocket Your Profits with AI! ',
        content1:"🎖️ Gain a competitive edge in your industry with cutting-edge knowledge.",
        content5:"📈 Unprecedented Growth: See how AI transforms numbers from flat lines to steep climbs.",
        content6:"💼 Efficiency & Productivity: Streamline operations and boost output with AI automation.",
        content7:"💰 Maximize ROI: Invest in AI and watch your return on investment multiply.",
        content8:"🔐 Secure & Reliable: Trust in AI's robust systems for secure, consistent growth.",
        content9:"👥 Customer Satisfaction: Enhance client experiences with AI's personalized touch.",
        content10:"🌟 Stay Ahead: Embrace AI and lead in the market with innovative solutions.",
    },
    {
        id:7,
        title: 'Unlock-AI-Excellence',
        image: blog7,
        category: "Who's Using AI",
        subtext: '@CrazyAnyone',
        date:"20th July, 2023",
        title2:"🚀 Who's Using AI",
        content1:"🌟 Top GPT Tools for Success",
        content5:"👨‍💻 Software Developers: Coding and development experts.",
        content6:"📊 Data Scientists: Data analysis and machine learning pros.",
        content7:"📢 Marketing Professionals: Creative and strategic marketing minds.",
        content8:"📈 Business Analysts: Corporate strategy and analysis experts.",
        content9:"💻 IT Professionals: Technology infrastructure and support specialists.",
        content10:"🏗️ Product Managers: Visionaries in product development and lifecycle.",
        content11:"🚀 Entrepreneurs: Innovators and startup leaders.",
        content13:"💹 Financial Analysts: Experts in financial data and economic trends.",
        content14:"🎨 UX/UI Designers: Creators of user-friendly and aesthetic designs.",
        content15:"🌐 Healthcare Professionals: Medical and health services experts.",
        content16:"📚 Educational Technologists: Innovators in learning technology.",
        content17:"🔬 Research Scientists: Pioneers in scientific discovery and innovation.",
    },
    {
        id:8,
        title: 'Popular-AI-Tools',
        image: blog8,
        category: 'Tools',
        subtext: '@Princess',
        date:"31st August, 2023",
        title2:"🚀 Few More ....",
        content1:"📚 Course Content Creator GPT: Comprehensive material development.",
        content2:"#️⃣ Hashtag Strategy GPT: Effective course promotion hashtags.",
        content3:"✍️ Prompt Crafting GPT: Interactive course prompts.",
        content4:"📈 Course Marketing GPT: Strategic marketing approaches.",
        content5:"🔍 SEO for Courses GPT: Search engine optimization.",
        content6:"🎨 Course Branding GPT: Brand identity establishment.",
        content7:"📱 Social Media Content GPT: Engaging course promotion posts.",
        content8:"🌐 Course Landing Page GPT: High-converting page designs.",
        content9:"🤝 Student Engagement GPT: Enhanced participation methods.",
        content10:"💲 Course Pricing Strategy GPT: Pricing advice.",
        content11:"🔎 Course Feedback Analysis GPT: Student feedback interpretation. ",
        content12:"🚀 Access Over 200 Custom-Crafted GPTs: Your Gateway to Advanced AI Mastery",
    },
    {
        id:9,
        title: 'Why-Should-Choose-AI-Mastermind-Course',
        image: blog9,
        category: 'Importance Of AI Mastermind',
        subtext: '@CrazyAnyone',
        date:"13th September,2023",
        title2:"🚀 Few More ....",
        content1:"❌Say goodbye to spending thousands on restrictive courses and training programs.",
        content2:"❌ Don't waste precious hours trying to master ChatGPT alone. Unlock its full potential with ease.",
        content3:"❌ Stop worrying about missing out on groundbreaking opportunities.",
        content4:"❌ Don't settle for average results – aim higher and achieve greatness with ChatGPT",
        content5:"❌ Move beyond mediocre, embrace excellence with advanced ChatGPT skills.",
        content6:"❌ Eliminate fears of falling behind in the fast-evolving tech scene.",
        content7:"❌ Move beyond mediocre, embrace excellence with advanced ChatGPT skills.",
        content8:"❌ Never worry about missing the tech wave again.",
        content9:"❌ Elevate from average to exceptional in your ChatGPT endeavors.",
        content10:"❌ Why settle? Achieve outstanding results with our guidance.",
        content11:"❌ Leave the fear of missing tech trends behind.",
    },
    {
        id:10,
        title: 'NFT Market - A Compact the Big Benefits',
        image: blog10,
        category: 'Tech',
        subtext: '@Princess',
        date:"29th November, 2023"
    },
    {
        id:11,
        title: 'Behind the Scenes of the creabik App',
        image: blog11,
        category: 'Arts',
        subtext: '@PandaOne',
        date:'13th March, 2023',
    },
    {
        id:12,
        title: 'Meet fennouni, Product Designer at GitHub',
        image: blog12,
        category: 'GIFs',
        subtext: '@FunnyGuy',
        date:'19th July, 2023'
    }
]